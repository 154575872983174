 
@import url('https://fonts.googleapis.com/css2?family=Faculty+Glyphic&family=Hubot+Sans:ital,wght@0,200..900;1,200..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body{
    font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
/* navbar  */

.input-class {
    background-color: #fff;
    height: 31px;
    padding: 3px 7px;
    line-height: normal;
    border: 1px solid #a6a6a6;
    border-top-color: #949494;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
    outline: 0;
    color: #111;
    font-size: 13px;
    width: 100%;
}

.input-class:focus{
    border-color: #e77600;
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
}

body{
    /* background-color: #EAEDED !important; */
    /* background-color: #FFF8FA;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23B8B5B7' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23000000'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E"); */

    background-color: #FEF0FF;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23EAEAEA' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23E1E1E1'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");

}
 

h2{
    font-size: 24px !important;
    font-weight: 500 !important;
}
.ptag{
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #3e3e3e !important;
}

label{
    font-size: 14px !important;
    font-weight: 700 !important;
}
.amz{
    color: #007185 !important;

}

.amz:hover{
    color: #c45500 !important;
    text-decoration: underline !important;

}

.amzbk{
    background-color: #007185 !important;
}
.atag{
    text-decoration: none;
}
.color-red{
    color: #c45500 !important;
}

.ft-table{
    font-size: 13px;
    border: 1px solid #e2e2e2;
    overflow-wrap: break-word;
    font-weight: 400;
    color: #333333 !important;
    border-radius: 3px;
    margin: 0px 0px 5px 0px;
}
.ft-table:hover{
    font-size: 13px;
    overflow-wrap: break-word;
    font-weight: 400;
    background-color: #fff1ac !important;
    color: #000 !important;
}

.action-label{
    font-size: 13px !important;
    font-weight: 400 !important;
    color: #0f1111 !important;
}

.ft-head-table{
    font-size: 14px;
    overflow-wrap: break-word;
    font-weight: 500;
    background-color: #d8f7fc !important;
    color: #0f1111 !important;

}

/* check color  */
/* Custom toggle switch styles */
.form-check-input {
    width: 2.5rem;
    height: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Change the background color when toggled on */
.form-check-input:checked {
    background-color: #ffd814 !important; /* Green background when checked */
}

/* Change the background color when toggled off */
.form-check-input:not(:checked) {
    background-color: #ccc; /* Gray background when unchecked */
}

/* Custom switch appearance */
.form-check-input:checked {
    /* box-shadow: 0 0 5px rgba(0, 255, 0, 0.5);  */
}

/* Custom label color */
.form-check-label {
    color: #000; /* Label color */
}

/* Change the label color when checked */
.form-check-input:checked + .form-check-label {
    color: #4caf50; /* Green text when checked */
}

/* Transition for smooth appearance */
.form-check-input {
    transition: background-color 0.2s, transform 0.2s; /* Smooth transition for background color and transform */
}

/* Remove the outline when the toggle is focused */
.form-check-input:focus {
    outline: none !important; /* Remove default outline */
    box-shadow: none !important; /* Remove any box-shadow (optional) */
}

/* Custom styles for better accessibility */
.form-check-input:focus-visible {
    outline: 2px solid #4caf50; /* Optional: Custom outline for accessibility (green color) */
    outline-offset: 2px; /* Optional: Add some space around the outline */
}

.bttn{
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
    background: #FFD814 !important;
    border: 1px solid #FCD200 !important;
    font-size: 13px;
    height: 31px;
    padding: 0 11px;
    text-align: center;
    width: 100%;
    min-width: 200px;
    font-weight: 500;
    color: #0F1111;
}
.bttn:hover{
    background: #F7CA00 !important;
    border-color: #F2C200 !important;
    box-shadow: 0 2px 5px 0 rgb(213 217 217 / 50%);
}

 
.btndanger {
    color: #fff;
    background-color: red;
    font-weight: 700;
    font-size: 14px;
    padding: 8px;
    border-radius: 3px;
    text-decoration: none;
}

.ft-table-mdl{
    font-size: 14px;
    /* border: 1px solid #e2e2e2; */
    overflow-wrap: break-word;
    font-weight: 400;
    color: #0f1111 !important;
    /* border-radius: 10px; */
    margin: 0px 0px 5px 0px;
}
.ft-table-mdl:hover{
    font-size: 14px;
    overflow-wrap: break-word;
    font-weight: 500;
    background-color: #d8f7fc !important;
    color: #0f1111 !important;
}
.hdrclas{
    background-color: #d8f7fc !important;
}


.bg-badge-1{
    background-color: #ffab00 !important;
    color: #000 !important;
}
.bg-badge-2{
    background-color: #00d25b !important;
    color: #000 !important;
}

.bg-badge-3{
    background-color: #F95F53 !important;
}

.bg-badge-4{
    background-color: #FFD814 !important;
    color: #000 !important;
}

.mxwdinpt{
    max-width: 90px !important;
}

.bg-az{
    background-color: #d8f7fc !important;
}


.bg-gradient-primary {
    background-image: linear-gradient(195deg, #ec407a, #d81b60) !important;
}
.bg-gradient-danger {
    background-image: linear-gradient(195deg, #ec4040, #d50d0d) !important;
}
.bg-gradient-success {
    background-image: linear-gradient(195deg, #5dec40, #28d50d) !important;
}
.bg-gradient-whatsapp {
    background-image: linear-gradient(195deg, #25D366, #08933b) !important;
}
.bg-gradient-info {
    background-image: linear-gradient(195deg, #49a3f1, #1a73e8);
}

.bg-gradient-secondary{
	background-image: linear-gradient(195deg, #51565b, #333539);
}
.bg-gradient-premium{
	background-image: linear-gradient(195deg, #f7630c, #fe9659);
}

.bg-gradient-black{
	background: linear-gradient(195deg, #42424a 0%, #191919 100%) !important;
}

.sptxt{
    font-size: 13px;
}

.form-control{
    box-shadow: 0 0 0 .0rem rgba(13, 110, 253, .25) !important;
    border-color: #c45500 !important;
}
.hedjk{
    font-size: 13px;
    font-weight: 700;
}
.hedjk1{
    font-size: 35px;
    font-weight: 700;
}

.chart-container {
    max-height: 300px;   /* Set max height */
    overflow-y: auto;    /* Enable vertical scrolling if content exceeds max height */
    width: 100% !important;
  }
  

  .dt-layout-row{
    display: flex !important;
        justify-content: space-between !important;
  }